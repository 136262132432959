import axios from 'axios'
import { getJwtString } from '../../components/Auth'
import API from '../../components/API'
import querystring from 'querystring'

axios.defaults.headers.common['Content-Type'] = 'application/json'

/**
 * @returns {Promise}
 */
export function getRoles (args) {
  let params = querystring.stringify(args)
  if (params) params = `?${params}`

  return axios.get(`${API('roles')}${params}`, {
    headers: {
      Authorization: getJwtString()
    }
  })
}

/**
 * Actualiza los roles de un usuario
 * @param {int} user_id
 * @param {Array} roles
 * @returns {Promise}
 */
export function putUserRoles (user_id, roles) {
  const values = roles.map(role_id => {
    return { user_id: parseInt(user_id), role_id }
  })

  return axios.put(`${API('pivots')}?action=role_user`, { values }, {
    headers: {
      Authorization: getJwtString()
    }
  })
}

/**
 * Elimina los roles de un usuario
 * @param {int} user_id
 * @returns {Promise}
 */
export function deleteUserRoles (user_id) {
  const data = {
    values: [{ user_id: parseInt(user_id) }]
  }

  return axios.delete(`${API('pivots')}?action=role_user`, {
    headers: {
      Authorization: getJwtString()
    },
    data
  })
}
