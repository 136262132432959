import axios from 'axios'
import querystring from 'querystring'

import API from '../../components/API'
import { getJwtString } from '../../components/Auth'

axios.defaults.headers.common['Content-Type'] = 'application/json'

/**
 * @returns {Promise}
 */
export function getUsers (args) {
  let params = querystring.stringify(args)
  if (params) params = `?${params}`

  return axios.get(`${API('users')}${params}`, {
    headers: {
      Authorization: getJwtString()
    }
  })
}

export function changePassword (password, newPassword, passwordConfirmation) {
  return axios.put(
    `${API('users')}?action=change_password`,
    {
      password,
      new_password: newPassword,
      password_confirmation: passwordConfirmation
    },
    {
      headers: {
        Authorization: getJwtString()
      }
    }
  )
}

export function postUser ({ args, body }) {
  let params = querystring.stringify(args)
  if (params) params = `?${params}`

  return axios.post(`${API('users')}${params}`, body, {
    headers: {
      Authorization: getJwtString()
    }
  })
}

export function putUser ({ args, body }) {
  let params = querystring.stringify(args)
  if (params) params = `?${params}`
  return axios.put(`${API('users')}${params}`, body, {
    headers: {
      Authorization: getJwtString()
    }
  })
}
